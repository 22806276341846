import { useState } from "react";
import { DislikeTwoTone, LikeTwoTone } from "@ant-design/icons";
import { CouponData } from "../../Models/Coupons";
import couponsService from "../../services/couponsService";
import { Space } from "antd";

interface LikeDislikeProps {
  couponData: CouponData;
}

function LikeDislike(props: LikeDislikeProps) {
  const [isLiked, setIsLiked] = useState(false);

  const likeDislikeHandle = (type: string) => {
    setIsLiked(!isLiked);
    couponsService
      .couponLikeDislike(props.couponData.lmd_id, type)
      .then((response) => console.log(response))
      .catch((error) => console.error(error));
  };

  return (
    <Space>
      <LikeTwoTone
        className="iconDesign"
        onClick={() => {
          likeDislikeHandle("like");
        }}
      />
      <DislikeTwoTone
        className="iconDesign"
        onClick={() => {
          likeDislikeHandle("dislike");
        }}
      />
    </Space>
  );
}

export default LikeDislike;
