import { Space } from "antd";

import { CouponData } from "../../Models/Coupons";
import LikeDislike from "../LikeDislike/LikeDislike";
import Share from "../Share/Share";
import Expire from "../Expire/Expire";
import "./Coupon.css";
import Terms from "../Terms/Terms";
import CouponDetail from "../CouponDetail/CouponDetail";

interface CouponProps {
  couponsList: CouponData[];
}

function Coupon(props: CouponProps) {
  return (
    <div>
      {props.couponsList.map((data: CouponData, index: number) => {
        return (
          <div key={data.coupon_id} className="couponContainer">
            <CouponDetail couponData={data} />
            <div className="couponFooter">
              <Space>
                <Terms couponData={data} />
                <Expire couponData={data} />
              </Space>
              <div className="couponIcons">
                <Space>
                  <LikeDislike couponData={data} />
                  <Share />
                </Space>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Coupon;
