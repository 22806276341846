import { ClockCircleTwoTone } from "@ant-design/icons";
import { Space, Typography } from "antd";
import moment from "moment";
import { CouponData } from "../../Models/Coupons";

interface ExpireProps {
  couponData: CouponData;
}

const { Text } = Typography;

const calculateRemainingDays = (endDate: string) => {
  const today = moment();
  const endDateTime = moment(endDate);
  const daysRemaining = endDateTime.diff(today, "days");
  return daysRemaining;
};

function Expire(props: ExpireProps) {
  const endDate = props.couponData.end_date;
  const remainingDays = calculateRemainingDays(endDate);
  const isExpiringToday = remainingDays === 0;
  const isExpiringSoon = remainingDays <= 5 && remainingDays > 0;

  return (
    <Space>
      <ClockCircleTwoTone className="iconDesign" />
      {isExpiringToday ? (
        <Text type="secondary">
          Expiring <Text strong>Today</Text>
        </Text>
      ) : isExpiringSoon ? (
        <Text type="secondary">
          Expiring in <Text strong>{remainingDays}</Text> days
        </Text>
      ) : (
        <Text type="secondary">
          Valid till {moment(endDate).format("MMM D, YYYY")}
        </Text>
      )}
    </Space>
  );
}

export default Expire;
