import { useState } from "react";
import { CouponData } from "../../Models/Coupons";
import { Modal, Typography } from "antd";
import { Markup } from "interweave";

interface TermsProps {
  couponData: CouponData;
}

const { Text } = Typography;

function Terms(props: TermsProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {props.couponData.terms_and_conditions &&
        props.couponData.terms_and_conditions !== "" && (
          <Text type="danger" className="couponDetailsLink" onClick={showModal}>
            <Text type="danger" strong>
              Show Details
            </Text>
          </Text>
        )}
      <Modal
        title="Terms & Conditions"
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[]}
      >
        <Markup content={props.couponData.terms_and_conditions} />
      </Modal>
    </div>
  );
}

export default Terms;
