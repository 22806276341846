import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CouponsService = {
  async getAllCoupons() {
    try {
      return await axios.get(`${BASE_URL}/getAllCoupons`);
    } catch {
      throw new Error("Failed to get the coupons from DB");
    }
  },

  async couponLikeDislike(lmd_id: number, type: string) {
    try {
      return await axios.post(`${BASE_URL}/couponLikeDislike`, {
        lmd_id,
        type,
      });
    } catch {
      throw new Error("Like / Dislike coupon was failed, please try again");
    }
  },
  // Function to fetch data from the API
  // LMDCouponsList: async (getOffers: string) => {
  //   try {
  //     const config = {
  //       API_KEY: process.env.REACT_APP_LMD_API_KEY_dummy,
  //       format: "json",
  //       incremental: 1,
  //     };
  //     const response = await axios.get(`${LMD_URL}/${getOffers}`, {
  //       params: config,
  //     });
  //     // return response.data;
  //     return sampledata;
  //   } catch (error) {
  //     throw new Error("Error fetching data from LMD");
  //   }
  // },

  // saveCouponsList: async (data: any) => {
  //   try {
  //     await axios.post(`${BASE_URL}/saveCouponsList`, data);
  //     return true;
  //   } catch (error) {
  //     throw new Error("Error while saving the LMD data");
  //   }
  // },
};

export default CouponsService;
