import { useState } from "react";
import { Markup } from "interweave";
import { Button, Divider, Modal, Space } from "antd";
import { CouponData } from "../../Models/Coupons";
import "./CouponDetail.css";
import couponsService from "../../services/couponsService";

interface CouponDetailProps {
  couponData: CouponData;
}

function CouponDetail(props: CouponDetailProps) {
  const couponData = props.couponData;
  const storeName = couponData.store.toLowerCase().split(".com")[0];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const likeDislikeHandle = (type: string) => {
    setIsLiked(!isLiked);
    couponsService
      .couponLikeDislike(props.couponData.lmd_id, type)
      .then((response) => console.log(response))
      .catch((error) => console.error(error));
  };

  const couponDetailsModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="couponData">
      <div>
        <img
          src={`/public/images/brand-logos/` + storeName + `.png`}
          alt={storeName + ` logo`}
        />
      </div>
      <div>
        <div className="couponTitle">
          <Markup content={couponData.title} />
        </div>
        <div className="couponDesc">
          <Markup content={couponData.description} />
        </div>
      </div>
      <div>
        <Space wrap>
          <Button type="dashed" danger onClick={couponDetailsModal}>
            {couponData.type === "Code" ? "SHOW COUPON CODE" : "GET THE DEAL"}
          </Button>
        </Space>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        width={couponData.terms_and_conditions ? "80%" : "50%"}
        footer={[]}
      >
        <div
          className="couponDetailContainer"
          style={{
            display:
              couponData.type === "Code" && couponData.terms_and_conditions
                ? "flex"
                : "block",
          }}
        >
          <div>
            <Space className="couponHeader">
              <div className="">
                <img
                  alt={storeName + ` logo`}
                  src={`src/assets/images/brand-logos/` + storeName + `.png`}
                />
              </div>
              <div className="">
                <Markup content={couponData.title} />
                <Markup content={couponData.description} />
              </div>
            </Space>
            <Divider className="couponHorizontalDivider" />
            <div className="couponBlock">
              <div>
                {couponData.type} has been activated on the website already
              </div>
              <div className="couponCode">
                {couponData.type === "Deal"
                  ? "Offer Activated"
                  : couponData.code}
                {couponData.type === "code" && (
                  <span>Click on the text to copy the code</span>
                )}
              </div>
              <div className="couponBottom">
                Visit{" "}
                <Button
                  type="link"
                  style={{ padding: "0" }}
                  target="blank"
                  href={couponData.smartLink}
                >
                  {storeName}
                </Button>{" "}
                to use the {couponData.type.toLowerCase()} at checkout
              </div>
              <div className="couponBottom">
                <Space>
                  <div>
                    Did the {couponData.type.toLowerCase()} work for you?
                  </div>
                  <Space>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => likeDislikeHandle("like")}
                    >
                      Yes
                    </Button>
                    <Button danger onClick={() => likeDislikeHandle("dislike")}>
                      No
                    </Button>
                  </Space>
                </Space>
              </div>
            </div>
          </div>
          {couponData.terms_and_conditions && (
            <Divider type="vertical" className="couponVerticalDivider" />
          )}
          {couponData.terms_and_conditions && (
            <div className="termsContainer">
              <Markup content={couponData.terms_and_conditions} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CouponDetail;
