import React, { useEffect, useState } from "react";
import Coupon from "../Coupon/Coupon";
import couponsService from "../../services/couponsService";
import "./Home.css";

function Home() {
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    couponsService
      .getAllCoupons()
      .then((response) => response.data.data && setCoupons(response.data.data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="couponsList">
      <Coupon couponsList={coupons} />
    </div>
  );
}

export default Home;
